import React, { useEffect } from "react";
import MenuBar from './pages/menu';
import Footer from "./pages/footer";
import HomePage from './pages/home';
import Actuality from './pages/actuality';
// import '@fontsource/roboto/300.css';
// import '@fontsource/roboto/400.css';
// import '@fontsource/roboto/500.css';
// import '@fontsource/roboto/700.css';




import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate
} from "react-router-dom";

function scrollToSection(event, sectionId) {
  event.preventDefault();
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth' });
  }
}

function MainComponent() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleNavigation = (event, sectionId) => {
        
        if(sectionId==="news-area"){
          navigate('/NEWS');
        }else{
          navigate('/');
          scrollToSection(event, sectionId);
        }
    };

    const sections = [
      { id: 'click-home', section: 'submenu-area' },
      { id: 'click-home-footer', section: 'submenu-area' },
      { id: 'click-news-footer', section: 'news-area' },
      { id: 'click-news', section: 'news-area' },
      { id: 'click-services', section: 'services-area' },
      { id: 'click-partenaires', section: 'partenaire-area' },
      { id: 'click-products', section: 'products-area' },
      { id: 'click-solutions', section: 'solutions-area' },
      { id: 'click-products-footer', section: 'products-area' },
      { id: 'click-contact', section: 'contacts-area' },
      { id: 'click-apropos', section: 'quisommes-area' },
    ];

    sections.forEach(({ id, section }) => {
      const element = document.getElementById(id);
      const handler = (event) => handleNavigation(event, section);
      element.addEventListener('click', handler);
      return () => element.removeEventListener('click', handler);
    });
  }, [navigate]);

  return (
    <>
      <MenuBar />
      <Routes>
        <Route path='/' exact element={<HomePage />} />
        {/* <Route path='/NEWS' exact element={<HomePage />} /> */}
        <Route path='/NEWSx' exact element={<Actuality />} />
      </Routes>
      <Footer />
    </>
  );
}

export default function App() {
  return (
    <BrowserRouter>
      <MainComponent />
    </BrowserRouter>
  );
}
