import React from "react";
import logo from "../assets/logo.png"
import { BsChevronRight , BsFacebook, BsInstagram,BsTiktok} from "react-icons/bs";

export default function Footer() {
    return <div id="footer-area"  className="footer w-full p-5 bg-black flex flex-col flex-wrap">
                    <div className=" flex justify-between border-b pb-10 border-b-gray-800">
                        <div className="flex flex-wrap md:justify-start justify-center">
                            <div className=" h-20 w-20 grid place-content-center md:border-r ">
                                <img src={logo} className=" h-12" />
                            </div>
                            <div className=" flex text-xs text-gray-400 md:w-96 md:mx-6 leading-5 text-center md:text-start">
                                Nous offrons sécurité intégrale et services tech :
                                contrôle accès, VOIP, vidéosurveillance, réseaux, sécurité incendie, ingénierie logicielle,
                                solutions sur mesure pour protéger vos actifs et optimiser vos opérations
                            </div>
                        </div>
                        <div className="md:flex hidden gap-1">
                                <a id="click-home-footer" className=" text-white text-sm font-semibold bg-white bg-opacity-20 h-max px-6 py-2 flex justify-between items-center cursor-pointer hover:bg-opacity-30 duration-700"> <BsChevronRight/>ACCUEIL </a>
                                <a id="click-news-footer"  className=" text-white text-sm font-semibold bg-white bg-opacity-20 h-max px-6 py-2 flex justify-between items-center cursor-pointer hover:bg-opacity-30 duration-700"> <BsChevronRight/>ACTUALITES</a>
                                <a id="click-products-footer" className=" text-white text-sm font-semibold bg-white bg-opacity-20 h-max px-6 py-2 flex justify-between items-center cursor-pointer hover:bg-opacity-30 duration-700"> <BsChevronRight/>PRODUCTS</a>
                        </div>
                    </div>
                    <div className="pt-4 flex flex-col justify-center">
                        <div className="flex gap-4  justify-center py">
                                <BsFacebook onClick={()=>window.open("https://www.facebook.com/profile.php?id=61561593221748",'_blank')} className=" text-white hover:text-blue-400 cursor-pointer duration-700"/>  
                                <BsInstagram onClick={()=>window.open("https://www.instagram.com/simba_sec_serv/",'_blank')}  className=" text-white hover:text-blue-400 cursor-pointer duration-700"/>  
                                <BsTiktok onClick={()=>window.open("https://www.facebook.com/profile.php?id=61561593221748",'_blank')}  className=" text-white hover:text-blue-400 cursor-pointer duration-700"/>  
                        </div>
                        <div className=" text-gray-400 text-center mt-5 text-xs">Copyright © 2024 <strong className=" text-blue-400">SIMBA SECURITY SERVICES</strong> ™ . Tous droits réservés.</div>
                    </div>


                  

    </div>
}