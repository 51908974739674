import React from "react";
import { BsEnvelope, BsPhone, BsPinMapFill } from "react-icons/bs";



export default function Contacts({ }) {
    const latitude = -4.306348;
    const longitude = 15.303336;
    const zoom = 15;

    return <div id="contacts-area" className=" w-full flex flex-col justify-center items-center ">
        <h2 className="text-3xl font-bold">CONTACTS</h2>
        <div className='w-52 h-1 bg-blue-700 my-6'></div>
        <div className=" text-center md:w-1/2 w-full text-gray-400 px-10">Vous pouvez utiliser les coordonnées ci-dessous pour nous contacter  et discuter plus en détail de nos produits et services.
            Nous sommes prêts à vous accueillir et nos équipes sont disponibles pour vous fournir les bonnes orientations.</div>

        <div className="flex md:flex-row flex-col gap-10 justify-center items-center">
            <div className="flex flex-col justify-center items-center my-10 cursor-pointer" onClick={()=>window.open(`https://www.google.com/maps?q=${latitude},${longitude}&z=${zoom}`,'_blank')}>
                <div className=" h-16 w-16 rounded bg-black grid place-content-center">
                        <BsPinMapFill className=" text-white"/>
                </div>
                <div className="text-xs my-4">KINSHASA,Gombe avenue du livre N293</div>
            </div>
            <div className="flex flex-col justify-center items-center my-10 cursor-pointer">
                <div className=" h-16 w-16 rounded bg-black grid place-content-center">
                        <BsPhone className=" text-white"/>
                </div>
                <div className="text-xs my-4">+243-898803787</div>
            </div>
            <div className="flex flex-col justify-center items-center my-10 cursor-pointer">
                <div className=" h-16 w-16 rounded bg-black grid place-content-center">
                        <BsEnvelope className=" text-white"/>
                </div>
                <div className="text-xs my-4">zhouyuanzheng@simbasec.cn</div>
            </div>
        </div>

    </div>
}