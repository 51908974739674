import camera from '../src/assets/prod/cam.png'
import access from '../src/assets/prod/access.png'
import barriere from '../src/assets/prod/barriere.png'
import barrieres from '../src/assets/prod/barrieres.png'
import door from '../src/assets/prod/door.png'
import drone from '../src/assets/prod/drone.png'
import facereco from '../src/assets/prod/facereco.png'
import fence from '../src/assets/prod/fence.png'
import incendie from '../src/assets/prod/incendie.png'
import panel from '../src/assets/prod/panel.png'
import machine from '../src/assets/prod/machine.png'
import rayonx from '../src/assets/prod/rayonx.png'

import dahua from '../src/assets/partenairs/dauha.png'
import honey from '../src/assets/partenairs/honey.png'
import litree from '../src/assets/partenairs/litree.png'
import ztk from '../src/assets/partenairs/ztk.png'
import hkvision from '../src/assets/partenairs/hkvision.png'

import bcc from '../src/assets/formations/bcc.jpeg'
import dauhagf from '../src/assets/formations/dauha.jpeg'
import ztkas from '../src/assets/formations/ztk.jpeg'

function getRandomColor() {
    const threshold = 200;
    let r, g, b;
    do {
        r = Math.floor(Math.random() * 256);
        g = Math.floor(Math.random() * 256);
        b = Math.floor(Math.random() * 256);
    } while (r > threshold && g > threshold && b > threshold);
    return `rgb(${r}, ${g}, ${b})`;
}



const phoneNumber = "+243898803787";
const message = "Bonjour cher client, comment on peut vous aider ?";
const url = "\nhttps://www.teal7.com";
const encodedMessage = encodeURIComponent(message) + encodeURIComponent(url);
const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

const accessControlList = [
    {
        name: "CONTRÔLE D\'ACCÈS",
        entreprise: 'HIKVISION',
        type: "Access Control",
        serie: "Time Attendance",
        model: "Ultra Face Recognition Terminals",
        caracteristic: "Ultra Face Recognition Terminals",
        image: "https://www.hikvision.com/content/dam/hikvision/products/S000000001/S000000080/S000000103/S000000104/OFR000134/M000019309/images/DS-K1T671T.png.thumb.1280.1280.png",
        link: "https://www.hikvision.com/fr/products/Access-Control-Products/Face-Recognition-Terminals/Ultra-Series/ds-k1t671t/",

    },
    {
        name: "CONTRÔLE D\'ACCÈS",
        entreprise: 'Dahua',
        type: "Access Control",
        serie: "Time Attendance",
        model: "ASA1222E-S",
        caracteristic: "Dahua Fingerprint Attendance standalone",
        image: "https://material.dahuasecurity.com/uploads/image/20230303/DHI-ASA1222E-S_2_thumb.png",
        link: "https://www.dahuasecurity.com/fr/products/All-Products/Access-Control--Time-Attendance/Time-Attendance/ASA1222E-S",

    },
    {
        name: "CONTRÔLE D\'ACCÈS",
        entreprise: 'HIKVISION',
        type: "Access Control",
        serie: "Time Attendance",
        model: "DS-K1T341AM-S",
        caracteristic: "DS-K1T341AM-S/Europe BV",
        image: "https://www.hikvision.com/content/dam/hikvision/products/S000000001/S000000080/S000000103/S000000107/OFR000141/M000039711/images/DS-K1T341AM1.png.thumb.1280.1280.png",
        link: "https://www.hikvision.com/fr/products/Access-Control-Products/Face-Recognition-Terminals/Value-Series/ds-k1t341am-s/",

    },
    {
        name: "CONTRÔLE D\'ACCÈS",
        entreprise: 'Dahua',
        type: "Access Control",
        serie: "Time Attendance",
        model: "ASA3213GL-MW",
        caracteristic: "FACT Time Attendance Terminal",
        image: "https://material.dahuasecurity.com/uploads/image/20201211/ASA3213GL-MW_thumb.png",
        link: "https://www.dahuasecurity.com/fr/products/All-Products/Access-Control--Time-Attendance/Time-Attendance/ASA3213GL-MW",

    },
    {
        name: "CONTRÔLE D\'ACCÈS",
        entreprise: 'Dahua',
        type: "Access Control",
        serie: "Time Attendance",
        model: "ASA1222G & ASA1222G-D",
        caracteristic: "Time & Attendance Terminal",
        image: "https://material.dahuasecurity.com/uploads/image/20190604/ASA1222G_side_thumb.png",
        link: "https://www.dahuasecurity.com/fr/products/All-Products/Access-Control--Time-Attendance/Time-Attendance/ASA1222G--ASA1222G-D",

    },
    {
        name: "CONTRÔLE D\'ACCÈS",
        entreprise: 'Dahua',
        type: "Access Control",
        serie: "Time Attendance",
        model: "ASA1222G & ASA1222G-D",
        caracteristic: "Time & Attendance Terminal",
        image: "https://www.hikvision.com/content/dam/hikvision/products/S000000001/S000000080/S000000103/S000000104/OFR000134/M000019309/images/DS-K1T671T.png.thumb.1280.1280.png",
        link: "https://www.dahuasecurity.com/fr/products/All-Products/Access-Control--Time-Attendance/Time-Attendance/ASA1222G--ASA1222G-D",

    },
    {
        name: "CONTRÔLE D\'ACCÈS",
        entreprise: 'HIKVISION',
        type: "Float/Plate/Tempered",
        serie: "Glass Break Tester",
        model: "DS-PDBGTS",
        caracteristic: "Time & Attendance Terminal",
        image: "https://www.hikvision.com/content/dam/hikvision/products/S000000001/S000000601/S000000937/S000000934/OFR001801/M000056786/images/BG-Tester-_Front-View.png.thumb.1280.1280.png",
        link: "https://www.hikvision.com/fr/products/Alarm-Products/wired-intrusion-alarm/ax-hybrid-pro/ds-pdbgts/",

    },
]

const antifireSystem = [
    {
        name: "SYSTÈME DE SÉCURITÉ INCENDIE",
        entreprise: 'Dahua',
        type: "Fire Alarm",
        serie: "Smoke Alarm",
        model: "HY-SA40A-R8",
        caracteristic: "Wireless Interconnected Smoke Alarm",
        image: "https://material.dahuasecurity.com/uploads/image/20230710/Image_HY-SA40A31_thumb.png",
        link: "https://www.dahuasecurity.com/fr/products/All-Products/Fire-Alarm/Smoke-Alarms/HY-SA40A-R8",
    },
    {
        name: "SYSTÈME DE SÉCURITÉ INCENDIE",
        entreprise: 'Dahua',
        type: "Fire Alarm",
        serie: "Heat Alarm",
        model: "HY-HT10A-R8",
        caracteristic: "Wireless Interconnected Heat Alarm",
        image: "https://material.dahuasecurity.com/uploads/image/20230710/HY-HT10A1_thumb.png",
        link: "https://www.dahuasecurity.com/fr/products/All-Products/Fire-Alarm/Heat-Alarms/HY-HT10A-R8",
    },
    {
        name: "SYSTÈME DE SÉCURITÉ INCENDIE",
        entreprise: 'Dahua',
        type: "Fire Alarm",
        serie: "Carbon Monoxide Alarms",
        model: "HY-GC30A-R8",
        caracteristic: "Wireless Interconnected Heat Alarm",
        image: "https://material.dahuasecurity.com/uploads/image/20240401/17_thumb.png",
        link: "https://www.dahuasecurity.com/fr/products/All-Products/Fire-Alarm/Carbon-Monoxide-Alarms/HY-GC30A-R8",
    },
    {
        name: "SYSTÈME DE SÉCURITÉ INCENDIE",
        entreprise: 'Dahua',
        type: "Fire Alarm",
        serie: "Carbon Monoxide Alarms",
        model: "HY-GC30A-R8",
        caracteristic: "Wireless Interconnected Carbon Monoxide Alarm",
        image: "https://material.dahuasecurity.com/uploads/image/20221226/HY-GC20B-R8_1_thumb.png",
        link: "https://www.dahuasecurity.com/fr/products/All-Products/Fire-Alarm/Carbon-Monoxide-Alarms/HY-GC20B-R8",
    },
]
const cameraList = [
    {
        name: "VIDÉO SURVEILLANCE",
        entreprise: 'Dahua',
        type: "Network Camera",
        serie: "Hubbel Serie Panoramic",
        model: "PSDW83242M-A360-D845L-S3",
        caracteristic: "32 MP Multi-Sensor 360° Panoramic PTZ Hubble WizMind Network Camera",
        image: "https://material.dahuasecurity.com/uploads/image/20230913/1_0_01_04_40097_349903475_crop_thumb.png",
        link: "https://www.dahuasecurity.com/fr/products/All-Products/Network-Cameras/WizMind-Series/Panoramic-Series/Hubble-Series/PSDW83242M-A360-D845L-S3=S3",
    },
    {
        name: "VIDÉO SURVEILLANCE",
        entreprise: 'Dahua',
        type: "Network Camera",
        serie: "2 Series",
        model: "IPC-HDW2449T-S-LED",
        caracteristic: "4MP Full-color Fixed-focal Eyeball Wizsense Network Camera",
        image: "https://material.dahuasecurity.com/uploads/cpq/prm-os-srv-res/smart/formal/Product/HQ/1_0_01_04_41524/ProductImage/1_0_01_04_41524_382754383_crop_thumb.png",
        link: "https://www.dahuasecurity.com/fr/products/All-Products/Network-Cameras/WizSense-Series/Full-color/2-Series/IPC-HDW2449T-S-LED",
    },
    {
        name: "VIDÉO SURVEILLANCE",
        entreprise: 'Dahua',
        type: "Network Camera",
        serie: "2 Series",
        model: "IPC-HFW2449M-AS-LED-B",
        caracteristic: "4MP Full-color Fixed-focal Bullet Wizsense Network Camera",
        image: "https://material.dahuasecurity.com/uploads/cpq/prm-os-srv-res/smart/formal/Product/HQ/1_0_01_04_41516/ProductImage/1_0_01_04_41516_389498410_crop_thumb.png",
        link: "https://www.dahuasecurity.com/fr/products/All-Products/Network-Cameras/WizSense-Series/Full-color/2-Series/IPC-HFW2449M-AS-LED-B",
    },
    {
        name: "VIDÉO SURVEILLANCE",
        entreprise: 'Dahua',
        type: "Network Camera",
        serie: "2 Series",
        model: "IPC-HUM4431S-L5",
        caracteristic: "4MP Fixed-focal Pinhole Network Camera",
        image: "https://material.dahuasecurity.com/uploads/image/20220125/1_0_01_04_37767_195689251_thumb.png",
        link: "https://www.dahuasecurity.com/fr/products/All-Products/Network-Cameras/Special-Series/Pinhole-Series/4-MP/IPC-HUM4431S-L5",
    },
    {
        name: "VIDÉO SURVEILLANCE",
        entreprise: 'Dahua',
        type: "HDCVI Cameras",
        serie: "4K",
        model: "HAC-EW2501",
        caracteristic: "5MP HDCVI IR-Fisheye Camera",
        image: "https://material.dahuasecurity.com/uploads/image/20181106/EW2501_image_thumb.png",
        link: "https://www.dahuasecurity.com/fr/products/All-Products/HDCVI-Cameras/Panorama-Series/HAC-EW2501",
    },
    {
        name: "VIDÉO SURVEILLANCE",
        entreprise: 'Dahua',
        type: "PTZ Cameras",
        serie: "X-Spans Series",
        model: "SDT5X425-4Z4-FA-2812",
        caracteristic: "4MP Starlight IR WizMind Network Dual-PTZ Camera",
        image: "https://material.dahuasecurity.com/uploads/cpq/prm-os-srv-res/smart/formal/Product/HQ/1_0_01_07_14589/ProductImage/1_0_01_07_14589_1060608727_crop_thumb.png",
        link: "https://www.dahuasecurity.com/fr/products/All-Products/PTZ-Cameras/X-Spans-Series/SDT5X425-4Z4-FA-2812",
    },
    {
        name: "VIDÉO SURVEILLANCE",
        entreprise: 'Dahua',
        type: "PTZ Cameras",
        serie: "X-Spans Series",
        model: "SDT8C442-4P-GA-APV-0280",
        caracteristic: "4MP 42x Starlight Dual Light Network Panoramic PTZ Camera",
        image: "https://material.dahuasecurity.com/uploads/cpq/prm-os-srv-res/smart/formal/Product/HQ/1_0_01_07_15292/ProductImage/1_0_01_07_15292_124672678_crop_thumb.png",
        link: "https://www.dahuasecurity.com/fr/products/All-Products/PTZ-Cameras/X-Spans-Series/SDT8C442-4P-GA-APV-0280",
    },
    {
        name: "VIDÉO SURVEILLANCE",
        entreprise: 'Dahua',
        type: "PTZ Cameras",
        serie: "X-Spans Series",
        model: "SDT4E425-8P-GB-APV1",
        caracteristic: "8MP+4MP 25x Starlight TiOC Network Panoramic & PTZ Camera",
        image: "https://material.dahuasecurity.com/uploads/cpq/prm-os-srv-res/smart/formal/Product/HQ/1_0_01_07_15532/ProductImage/1_0_01_07_15532_586508358_thumb.png",
        link: "https://www.dahuasecurity.com/fr/products/All-Products/PTZ-Cameras/X-Spans-Series/SDT4E425-8P-GB-APV1",
    },
    {
        name: "VIDÉO SURVEILLANCE",
        entreprise: 'Dahua',
        type: "Explosion-Proof & Anti-Corrosion",
        serie: "Anti-Corrosion Cameras",
        model: "SDZW2000T-SL",
        caracteristic: "2MP Anti-corrosion IR Network Camera",
        image: "https://material.dahuasecurity.com/product/20180718/SDZW2000T-SL_thumb.png",
        link: "https://www.dahuasecurity.com/fr/products/All-Products/Explosion-Proof--Anti-Corrosion/Anti-Corrosion-Cameras/SDZW2000T-SL",
    },
]

const productsList = [
    { name: 'VIDÉO SURVEILLANCE', image: camera, link: whatsappURL, products: cameraList },
    { name: 'SYSTÈME DE SÉCURITÉ INCENDIE', image: incendie, link: whatsappURL, products: antifireSystem },
    { name: 'BARRIÈRE LEVANT', image: barriere, link: whatsappURL },
    { name: 'CONTRÔLE D\'ACCÈS', image: access, link: whatsappURL, products: accessControlList },
    // { name: 'BARRIÈRES', image: barrieres, link: whatsappURL },
    { name: 'CONTRÔLE DES BARRAGES AUX RAYONS X', image: rayonx, link: whatsappURL },
    { name: 'RECONNAISSANCE FACIALE', image: facereco, link: whatsappURL },
    { name: 'PORTE DE DÉTECTION', image: door, link: whatsappURL },
    { name: 'CLÔTURE ÉLECTRIQUE', image: fence, link: whatsappURL },
    { name: 'LES SYSTÈMES DE DRONES MILITAIRES', image: drone, link: whatsappURL },
    { name: 'MACHINE DE BARRAGE ROUTIER', image: machine, link: whatsappURL },
];





const partnersList = [
    {
        name: "dahua",
        link: "https://www.dahuasecurity.com/",
        image: dahua
    },
    {
        name: "ztkco",
        link: "https://www.ztkco.com/",
        image: ztk
    },
    {
        name: "honeywell",
        link: "https://www.honeywell.com/",
        image: honey
    },
    {
        name: "hikvision",
        link: "https://www.hikvision.com/",
        image: hkvision
    },
    {
        name: "litree",
        link: "https://www.litree.com/",
        image: litree
    }
];


var formationList = [
    {
        title: "Formation des clients à Dahua",
        description: "Nous offrons des formations complètes sur les produits et solutions de sécurité de Dahua. Nos sessions couvrent l'installation, la configuration et l'utilisation optimale des systèmes pour garantir une sécurité maximale.",
        image: dauhagf
    },
    {
        title: "Formation des clients à ZKTCo",
        description: "Nos formations à ZKTCo visent à équiper les clients avec les connaissances nécessaires pour utiliser les systèmes de contrôle d'accès et de gestion du temps. Nous nous concentrons sur les meilleures pratiques pour maximiser l'efficacité et la sécurité.",
        image: ztkas
    },
    {
        title: "Installations Banque Centrale de la RDC",
        description: "Nous avons réalisé des installations de systèmes de sécurité avancés pour la Banque Centrale de la RDC. Nos services incluent la mise en place de caméras de surveillance, de systèmes d'alarme et de contrôle d'accès pour assurer une protection totale.",
        image: bcc
    }
];



export { getRandomColor, productsList, partnersList, formationList }