import React from "react";
import { BsGearFill, BsWindowDesktop } from "react-icons/bs";
import { FaFaucetDrip, FaCodeFork } from "react-icons/fa6";
import {getRandomColor} from '../constants'
export default function Services() {

    const data = [
        {
            icon: <BsGearFill />,
            title: `Système de Sécurité`,
            description: `Les systèmes de sécurité modernes intègrent des technologies avancées pour protéger les biens et les personnes, offrant surveillance, contrôle d'accès et alertes en temps réel.`
        },
        {
            icon: <BsWindowDesktop />,
            title: `Informations numériques`,
            description: `La gestion des informations numériques implique le stockage, la protection et l'accès aux données numériques, garantissant leur intégrité, confidentialité et disponibilité.`
        },
        {
            icon: <FaFaucetDrip />,
            title: `Traitement de l'eau`,
            description: `Le traitement de l'eau inclut des processus physiques, chimiques et biologiques pour purifier l'eau, la rendant sûre pour la consommation humaine et pour d'autres usages industriels.`
        },
        {
            icon: <FaCodeFork />,
            title: `Communications`,
            description: `Les technologies de communication englobent les réseaux téléphoniques, internet et sans fil, facilitant l'échange d'informations à travers des distances globales de manière rapide et fiable.`
        },
    ];

    return (
        <div id="services-area"  className="w-full  flex flex-wrap p-4 justify-center items-center gap-2">
            {data.map((service, index) => (
                <div key={index} className="w-80 ">
                    <div className="bg-white rounded-lg p-6 border border-gray-100 flex flex-col items-start">
                        <div className="text-3xl mb-4 rounded-full border p-6 ">
                            <div className="rounded-full border p-3 " >
                                <div className="rounded-full border p-3 text-white" style={{ background: getRandomColor() }}>
                                    {service.icon}
                                </div>
                            </div>
                        </div>
                        <h3 className="font-bold mb-2">{service.title}</h3>
                        <p className="text-gray-400 text-left text-sm leading-6">{service.description}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}
