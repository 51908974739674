import React from 'react';
import products from '../assets/prod/all.png'

const AboutUsComponent = () => {
  return (
    <div id="quisommes-area"  className="bg-gray-100 py-12 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center justify-center">
      {/* Left Section */}
      <div className="md:w-1/3 md:pr-8 mb-6 md:mb-0  mx-10 md:mx-0">
        <h2 className="text-3xl font-bold">QUI SOMMES-NOUS ?</h2>
        <div className='w-52 h-1 bg-blue-700 my-6'></div>
        <p className=" text-gray-700 leading-relaxed ">
          Notre entreprise est spécialisée dans la fourniture d'une sécurité complète et de services technologiques, y compris les systèmes de contrôle d'accès, les solutions VOIP, la sécurité des caméras vidéo, le déploiement de réseaux, les systèmes de sécurité incendie et l'ingénierie logicielle. Nous proposons des solutions de pointe sur mesure pour protéger vos actifs et optimiser vos opérations.
        </p>
      </div>

      {/* Right Section */}
      <div className="md:w-1/3 mx-10 md:mx-0">
        <img className="" src={products} alt="SIMBA" />
      </div>
    </div>
  );
}

export default AboutUsComponent;
