import React,{useState} from "react";
import { BsJustify,BsXLg } from "react-icons/bs";
import logo from "../assets/logo.png"

export default function MenuBar() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };


    var desktopitemstyle=`text-white hover:bg-white hover:bg-opacity-20 duration-700 font-semibold  text-xs px-4 py-2 rounded-md cursor-pointer`
    return (
        <nav id="menu-area"  className="bg-gray-800 p-4 py-6 md:py-4  w-full z-10 bg-opacity-90 ">
        {/* <nav id="menu-area"  className="bg-gray-800 p-4 py-6 md:py-4  w-full z-10 bg-opacity-90 fixed top-0"> */}
            <div className="container mx-auto flex justify-between items-center">
                <div className= " h-12 w-12 bg- rounded-2xl text-lg font-bold grid place-content-center">
                    <img src={logo} className="h-12 w-12" />
                </div>
                <div className="hidden md:flex space-x-1 my-2">
                    <a id="click-home" className={`${desktopitemstyle}`}>ACCUEIL</a>
                    <a id="click-news" className={`${desktopitemstyle}`}>ACTUALITES</a>
                    <a id="click-services" className={`${desktopitemstyle}`}>SERVICES</a>
                    <a id="click-partenaires" className={`${desktopitemstyle}`}>PARTENAIRES</a>
                    <a id="click-products" className={`${desktopitemstyle}`}>PRODUITS</a>
                    <a id="click-solutions" className={`${desktopitemstyle}`}>SOLUTIONS</a>
                    <a id="click-contact" className={`${desktopitemstyle}`}>CONTACTS</a>
                    <a id="click-apropos" className={`${desktopitemstyle}`}>APROPOS</a>
                </div>
                <div className="md:hidden flex items-center">
                    <button onClick={toggleMenu} className="text-white focus:outline-none">
                        {isOpen ? <BsXLg className="h-6 w-6" /> : <BsJustify className="h-6 w-6" />}
                    </button>
                </div>
            </div>
            {isOpen && (
                <div className="md:hidden pt-8">
                    <a id="click-home" className="block text-white px-2 py-5 border-t border-gray-600 hover:bg-white hover:bg-opacity-25 text-xs font-bold">ACCUEIL</a>
                    <a id="click-news" className="block text-white px-2 py-5 border-t border-gray-600 hover:bg-white hover:bg-opacity-25 text-xs font-bold">ACTUALITES</a>
                    <a id="click-services" className="block text-white px-2 py-5 border-t border-gray-600 hover:bg-white hover:bg-opacity-25 text-xs font-bold">SERVICES</a>
                    <a id="click-partenaires" className="block text-white px-2 py-5 border-t border-gray-600 hover:bg-white hover:bg-opacity-25 text-xs font-bold">PARTENAIRES</a>
                    <a id="click-products" className="block text-white px-2 py-5 border-t border-gray-600 hover:bg-white hover:bg-opacity-25 text-xs font-bold">PRODUITS</a>
                    <a id="click-solutions" className="block text-white px-2 py-5 border-t border-gray-600 hover:bg-white hover:bg-opacity-25 text-xs font-bold">SOLUTIONS</a>
                    <a id="click-contact" className="block text-white px-2 py-5 border-t border-gray-600 hover:bg-white hover:bg-opacity-25 text-xs font-bold">CONTACTS</a>
                    <a id="click-apropos" className="block text-white px-2 py-5 border-t border-gray-600 hover:bg-white hover:bg-opacity-25 text-xs font-bold">APROPOS</a>
                </div>
            )}
        </nav>
    );
}