import React,{useState} from 'react';
import Pagination from '@mui/material/Pagination';
import formationimage from '../assets/formations/dauha.jpeg'

export default function Actuality() {

    // const [paginatorData, setPaginatorData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);

    var dataNews = [{
        title: "Ceci est un titre de commerce",
        image: formationimage,
        content: "Ceci est un w-64 titre de commerce   un w-64 titre de commerce   un w-64 titre de commerce Ceci est un titre de commerce Ceci est un titre de commerce",
        date: '24, janv.2039'
    }, {
        title: "Ceci est un titre de commerce",
        image: formationimage,
        content: "Ceci est un w-64 titre de commerce   un w-64 titre de commerce   un w-64 titre de commerce Ceci est un titre de commerce Ceci est un titre de commerce",
        date: '24, janv.2039'
    }, {
        title: "Ceci est un titre de commerce",
        image: formationimage,
        content: "Ceci est un w-64 titre de commerce   un w-64 titre de commerce   un w-64 titre de commerce Ceci est un titre de commerce Ceci est un titre de commerce",
        date: '24, janv.2039'
    }, {
        title: "Ceci est un titre de commerce",
        image: formationimage,
        content: "Ceci est un w-64 titre de commerce   un w-64 titre de commerce   un w-64 titre de commerce Ceci est un titre de commerce Ceci est un titre de commerce",
        date: '24, janv.2039'
    }, {
        title: "Ceci est un titre de commerce",
        image: formationimage,
        content: "Ceci est un w-64 titre de commerce   un w-64 titre de commerce   un w-64 titre de commerce Ceci est un titre de commerce Ceci est un titre de commerce",
        date: '24, janv.2039'
    }, {
        title: "Ceci est un titre de commerce",
        image: formationimage,
        content: "Ceci est un w-64 titre de commerce   un w-64 titre de commerce   un w-64 titre de commerce Ceci est un titre de commerce Ceci est un titre de commerce",
        date: '24, janv.2039'
    }, {
        title: "Ceci est un titre de commerce",
        image: formationimage,
        content: "Ceci est un w-64 titre de commerce   un w-64 titre de commerce   un w-64 titre de commerce Ceci est un titre de commerce Ceci est un titre de commerce",
        date: '24, janv.2039'
    },]

    return <div className='py-20 flex flex-col justify-center items-center'>
        <div className="main-new h-96 w-full" style={{ backgroundImage: `url(${formationimage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} >
            <div className='w-full p-4 h-96 bg-opacity-70  bg-black'
            >               <div className=' font-thin text-3xl px-4 pt-6 text-white'>A la une d'actualités</div>
                <div className=' font-bold text-5xl px-4 text-white'>Controle de scolarite</div>
            </div>
        </div>

        <div className=' flex flex-wrap justify-center items-center  w-full'>
            {
                dataNews.slice(currentPage, currentPage + 4).map((news, i) => {
                    return <div className='w-64 m-4'>
                        <div className='h-60 bg-black w-64 ' style={{ backgroundImage: `url(${news.image})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>image</div>
                        <div className=' text-xs font-semibold my-4 uppercase'>{news.title}</div>
                        <div className=' text-xs text-gray-400 my-2'>{news.content}</div>
                        <div className=' text-xs text-white cursor-pointer bg-blue-800 w-max px-5 py-3 my-5 hover:bg-blue-950 duration-700'>LIRE PLUS</div>
                    </div>
                })
            }

        </div>

        {/* <div className='my-5'><Pagination count={10} variant="outlined" shape="rounded" onChange={(e) => setCurrentPage(currentPage)} /></div> */}

    </div>
}



