import React, { useState } from 'react';
import { formationList } from '../constants';

const Formations = () => {

    const Formation = ({ formation }) => {
        const [showD, setShowD] = useState(false)
        return <div  className= "w-96 cursor-pointer" onMouseOver={() => setShowD(true)} onMouseLeave={() => setShowD(false)} >
            <div className="relative shadow-md flex flex-col justify-end rounded-lg overflow-hidden h-72 " style={{ backgroundImage: `url(${formation.image})`, backgroundSize: 'cover', }}>
                <div className="p-4 absolute bg-black bg-opacity-50  h-full w-full flex flex-col justify-end">
                    <h3 className={`mb-2 text-white text-sm ${showD === true ? ' font-bold':' font-light'}  uppercase`}>{formation.title}</h3>
                    <p className={`${showD === true ? 'flex':'hidden'}  text-white text-sm duration-700`}>{formation.description}</p> 
                </div>
            </div>
        </div>
    }

    return (
        <div  id="formations-area"  className="flex flex-wrap justify-center items-center w-full gap-2 my-10">
            {formationList.map((formation, index) => (
                <Formation key={index} formation={formation} />
            ))}
        </div>
    );
};

export default Formations;
