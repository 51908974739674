
import React, { useState } from 'react';
import { BsFileEarmarkPdf } from "react-icons/bs";

var activitiesList = [
    {
        name: "Bâtiments",
        image: "https://www.hikvision.com/content/dam/hikvision/en/marketing/image/solution/solutions-by-industry/buildings/Secure-your-property-and-safeguard-your-well-being-in-a-comprehensive-way.png",
        content: "Minimisez les fausses alarmes grâce à la vidéoprotection IA 24h/24 et 7j/7. Recevez des notifications en temps réel pour des menaces réelles. Enregistrez des preuves en HD et déclenchez des alertes instantanées en cas de chute.",
        bronchure: "https://www.hikvision.com/content/dam/hikvision/en/brochures/solution-by-industry/buildings/Brochure-Hikvision-Solutions-for-Smart-Buildings.pdf",
        details: "https://www.hikvision.com/fr/solutions/solutions-by-industry/buildings/"
    },
    {
        name: "Hôtels",
        image: "https://www.hikvision.com/content/dam/hikvision/en/marketing/image/solution/solutions-by-scenario/hotels/Hotels-3D-map.jpg",
        content: "Les hôtels offrent une hospitalité temporaire, mais s'engagent à créer l'expérience la plus agréable possible.Cette solution intègre sécurité vidéo, systèmes d'alarme et affichages vidéo attrayants pour une gestion unifiée, sécurisée et efficace.",
        bronchure: "https://www.hikvision.com/content/dam/hikvision/en/brochures/solution-by-industry/buildings/Brochure-Hikvision-Solutions-for-Smart-Buildings.pdf",
        details: "https://www.hikvision.com/fr/solutions/solutions-by-industry/buildings/"
    },
   
    {
        name: "Hôpitaux",
        image: "https://www.hikvision.com/content/dam/hikvision/en/marketing/image/solution/solutions-by-industry/hospitals/hospitals-media1.jpg",
        content: "Les solutions Hikvision pour les hôpitaux sécurisent le parcours des patients dès leur arrivée, assurant sécurité et soin. Elles identifient aussi les menaces, raccourcissent les délais de réponse et optimisent l'efficacité.",
        bronchure: "https://www.hikvision.com/content/dam/hikvision/en/brochures/solution-by-industry/hospital/Hikvision-Solutions-for-Hospitals.pdf",
        details: "https://www.hikvision.com/fr/solutions/solutions-by-industry/hospitals/"
    },
];
var scenatioList = [
    {
        name: "Usines",
        image: "https://www.hikvision.com/content/dam/hikvision/en/marketing/image/solution/solutions-by-scenario/solutions-by-scenario%E2%80%93factory/SbS_factory-inode-bitmap.jpg",
        content: "La solution Hikvision pour les usines assure une production efficace et sécurisée, gérant les défis tels que la mobilité du personnel et les risques d'incendie grâce à des produits professionnels et des sous-systèmes spécialisés.",
        bronchure: "",
        details: "https://www.hikvision.com/fr/solutions/solutions-by-scenario/factory/"
    },
    {
        name: "Stations-Services",
        image: "https://www.hikvision.com/content/dam/hikvision/en/marketing/image/solution/solutions-by-scenario/gas-stations/Gas-Stations_Overview-02.png",
        content: "Les stations-service, confrontées à des défis de sécurité et de protection contre l'incendie, doivent protéger employés, clients et biens. Les solutions Hikvision offrent sécurité vidéo, systèmes d'alarme et plateformes unifiées pour améliorer la sécurité et optimiser les opérations.",
        bronchure: "",
        details: "https://www.hikvision.com/fr/solutions/solutions-by-scenario/gas-stations/"
    }, {
        name: "Parkings",
        image: "https://www.hikvision.com/content/dam/hikvision/en/marketing/image/solution/solutions-by-scenario/parking-lot/Parking-lot-3D-map.jpg",
        content: "Les solutions par scénario Hikvision pour les parcs de stationnement optimisent l'utilisation des places en guidant rapidement les conducteurs vers des espaces libres, rationalisant ainsi le flux et ajoutant des couches de sécurité.",
        bronchure: "https://www.hikvision.com/content/dam/hikvision/en/brochures/solution-by-industry/buildings/Brochure-Hikvision-Solutions-for-Smart-Buildings.pdf",
        details: "https://www.hikvision.com/fr/solutions/solutions-by-industry/buildings/"
    },
    
];
var applicationList = [
    {
        name: "Détection des infractions",
        image: "https://www.hikvision.com/content/dam/hikvision/en/marketing/image/solution/solution-by-function/violation-detection/traffic-light.jpg",
        content: "Le système Hikvision utilise la reconnaissance multi-images et le suivi des véhicules pour détecter les infractions routières, réguler le comportement des conducteurs, réduire les accidents et améliorer la sécurité routière.",
        bronchure: "",
        details: "https://www.hikvision.com/fr/solutions/solutions-by-function/violation-detection/"
    },
    {
        name: "Détection des informations du véhicule",
        image: "https://www.hikvision.com/content/dam/hikvision/en/marketing/image/solution/solution-by-function/speed-measurement/speedmeasurement07.jpg",
        content: "Le système de détection de véhicules de Hikvision utilise l'apprentissage profond pour reconnaître les caractéristiques des véhicules, détecter les infractions et fournir des données de trafic avancées avec haute efficacité.",
        bronchure: "",
        details: "https://www.hikvision.com/fr/solutions/solutions-by-function/vehicle-information-detection/#:~:text=rapidement%20les%20preuves-,Routes,-Autoroutes"
    }, 
];
var pmeList = [
    {
        name: "Solutions de sécurité globales",
        image: "https://www.hikvision.com/content/dam/hikvision/en/marketing/image/solution/solutions-for-small-&-medium-business/end-to-end-solutions/View-in-color.jpg",
        content: "Les systèmes de sécurité vidéo alimentés par l'IA vous alertent et agissent automatiquement pour déjouer les risques de sécurité autour de vos périmètres et entrées, à tout moment et sous n'importe quelle lumière.",
        bronchure: "",
        details: "https://www.hikvision.com/fr/solutions/solutions-by-function/violation-detection/"
    }
];




const Solutions = () => {
    const [activeTab, setActiveTab] = useState(0);

   



    const SolutionElement = ({ solution }) => {
        return <div className='w-full flex md:flex-row flex-col  my-2'>
            <div className='w-1/3 h-32 bg-slate-300 md:m-1' style={{ backgroundImage: `url(${solution.image})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} ></div>
            <div className=' w-2/3 md:mx-4 flex flex-col justify-between'>
                <div>
                    <div className=' font-semibold'>{solution.name}</div>
                    <div className=' text-xs text-slate-500'>{solution.content}</div>
                </div>
                <div className='flex '>
                    <div className=' text-xs text-blue-700 font-medium bg-slate-200 cursor-pointer duration-700 hover:bg-slate-50 w-max px-5 py-2' onClick={() => window.open(solution.details, "_blank")} >Voir plus</div>
                    {solution.bronchure?<div className=' text-xs   flex items-center  mx-3 text-blue-700 font-medium bg-slate-200 cursor-pointer duration-700 hover:bg-slate-50 w-max px-5 py-2' onClick={() => window.open(solution.bronchure, "_blank")} >
                        <BsFileEarmarkPdf className={` md:text-lg text-sm mx-3`}/>
                        BROCHURE 
                    </div>:null}
                </div>
            </div>
        </div>
    }

    const tabs = [
        { id: 0, label: `Secteur d'Activités`, content: activitiesList.map((solution) => { return <SolutionElement solution={solution} /> }) },
        { id: 1, label: `Solutions par Scénario`, content: scenatioList.map((solution) => { return <SolutionElement solution={solution} /> }) } ,
        { id: 2, label: `Solutions par Application`, content: applicationList.map((solution) => { return <SolutionElement solution={solution} /> }) },
        { id: 4, label: 'Solutions pour les PME', content: pmeList.map((solution) => { return <SolutionElement solution={solution} /> }) },
    ];

    return (
        <div id="solutions-area" className="w-full md:w-1/2 mx-auto p-4 ">
                    <h2 className="text-3xl font-bold mb-10" >NOS SOLUTIONS</h2>
            <div className="flex border-b border-gray-300">
                {tabs.map((tab) => (
                    <button
                        key={tab.id}
                        className={`flex-1 py-2 px-2 text-xs text-center ${activeTab === tab.id
                            ? 'bg-blue-600 text-white font-bold duration-700'
                            : 'hover:text-white text-gray-500 hover:bg-blue-600 bg-gray-300 duration-700'
                            }`}
                        onClick={() => setActiveTab(tab.id)}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>
            <div className="p-4">
                {tabs.map((tab) => (
                    <div
                        key={tab.id}
                        className={`${activeTab === tab.id ? 'block' : 'hidden'
                            }`}
                    >
                        {tab.content}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Solutions;
