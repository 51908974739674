
import React from 'react';

const PartnersList = ({ partners }) => {
    return (
        <div id="partenaire-area"  className="flex flex-wrap justify-center bg-slate-200 ">
            {partners.map((partner, index) => (
                <div key={index} className="max-w-xs m-4 cursor-pointer h-28 w-36">
                    <a href={partner.link} target="_blank" rel="noopener noreferrer">
                        <img src={partner.image} alt={partner.name} className="" />
                    </a>
                </div>
            ))}
        </div>
    );
}

export default PartnersList