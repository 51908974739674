import React from 'react';
import videobg from '../assets/bg.mp4';
import cam from '../assets/prod/cam.png';
import drone from '../assets/prod/drone.png';
import acces from '../assets/prod/access.png';
import panel from '../assets/prod/panel.png';

const SubMenu = () => {
    return (
        <div id="submenu-area"  className="w-full md:h-screen h-96  relative md:mt-0 mt-24 bg-black">
            <video className="h-full w-full object-cover" autoPlay loop muted >
                <source src={videobg} type="video/mp4" />
            </video>
            <div className="absolute inset-0 flex items-center justify-center p-4">
                <div className="flex flex-col md:flex-row items-center justify-between w-full  bg-black bg-opacity-45 md:p-8 p-5 rounded-lg shadow-lg">
                    <div className="md:w-1/2 mb-8 md:mb-0">
                        <h1 className="text-3xl font-normal mb-4 text-white">Votre sécurité</h1>
                        <h1 className="md:text-7xl text-4xl font-bold mb-4  text-white">Est Notre Priorité</h1>
                        <p className="mb-4  text-white text-sm md:text-sm leading-6">
                            Nous sommes spécialisés dans la fourniture d’une sécurité <br /> sur mesure et de pointe
                            et des solutions technologiques pour protéger <br className='md:flex hidden' />les actifs et optimiser les opérations.
                        </p>
                        <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
                            CONTACTEZ-NOUS
                        </button>
                    </div>
                    <div className="hidden  relative bg-black md:w-1/2 md:grid grid-cols-2 gap-0 justify-items-center place-items-center">
                        <div className=" h-56 w-56 absolute -top-44  flex  rounded-full  items-center justify-center bg-white ">
                            <img src={cam} alt="" srcSet="" />
                        </div>
                        <div className=" h-64 w-64 absolute top-0 right-10  rounded-full  flex items-center justify-center bg-white ">
                            <img src={panel} alt="" srcSet="" />
                        </div>
                        <div className=" h-56 w-56 absolute top-44 right-22 rounded-full  flex items-center justify-center bg-white ">
                            <img src={drone} alt="" srcSet="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubMenu;
