
import React from "react";
import SubMenu from "../pages/sub.menu"
import AboutUsComponent from "../pages/whoweare"
import Services from "../pages/services"
import Products from "../pages/products"
import PartnersList from "../pages/partenaires"
import Formations from "../pages/fomations"
import { partnersList } from "../constants"
import Contacts from "../pages/contacts"
import Solutions from "../pages/solutions"

export default function HomePage() {
    return <>
        <SubMenu />
        <AboutUsComponent />
        <Services />
        <Solutions />
        <Products />
        <PartnersList partners={partnersList} />
        <Formations />
        <Contacts />
    </>
}