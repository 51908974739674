
import React, { useState } from 'react';
import { productsList,cameraList } from '../constants';
import { BsBookmarkFill,BsJustify, BsSearch, BsWhatsapp } from 'react-icons/bs';
import {FaTimes} from 'react-icons/fa'
import image from "../assets/prod/cam.png"

function Products() {
    const [searchQuery, setSearchQuery] = useState('');
    const filteredData = productsList.filter(item =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div id="products-area" className="px-12 md:px-28 py-10">
            <div className=' border h-10 my-8 py-1 px-4 md:mx-24  w-auto md:w-80  flex items-center rounded'>
                <BsSearch className=' text-gray-400' />
                <input
                    type="text"
                    placeholder="Rechercher un produit"
                    className="p-2 rounded  w-full bg-gray-500 bg-transparent outline-none "
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>
            <div className=" flex flex-wrap justify-center items-center gap-8">
                {filteredData.length ? filteredData.map((item, index) => (
                    <ProductItem key={index} item={item} />
                )) : <div className="flex flex-col items-center justify-center h-max mb-10">
                    <h1 className="text-3xl font-semibold mb-4 text-gray-300">Aucun produit retrouvé</h1>
                    <p className="text-gray-600">Nous n'avons trouvé aucun produit correspondant à votre recherche.</p>
                </div>}
            </div>
        </div>
    );
}


const ProductItem = ({ item }) => {
    const [showDetails, setShowDetails] = useState(false)
    return <div className="p-4 border cursor-pointer  duration-700 border-gray-200 w-96 md:w-60 h-80  rounded flex flex-col justify-between">
    {/* return <div className="p-4 border cursor-pointer  duration-700 border-gray-200 w-96 md:w-80 h-80  rounded flex flex-col justify-between"> */}
        {showDetails === true  ? <DetailsProducts type={item.name} products={item.products} closeModal={setShowDetails} /> : null}
        <img src={item.image || 'placeholder.jpg'} alt={item.name} className="w-full h-48 object-contain mb-2" />
        <h2 className=" mb-2 text-center text-gray-600 text-xs font-normal">{item.name}</h2>
        <div className=" flex gap-2">
            <div onClick={() => window.open(item.link, "_blank")} className="whats-pp cursor-pointer flex bg-green-500 text-white w-max hover:bg-green-700 px-2 py-2 duration-700">
                <BsWhatsapp />
                <div className=' text-xs mx-2'>Ecrivez-nous</div>
            </div>
            <div onClick={() => setShowDetails(!showDetails)} className="border cursor-pointer flex  text-gray-800 w-max hover:bg-gray-100 px-2 py-2 duration-700">
                <BsJustify />
            </div>
        </div>
    </div>
}
const DetailsProducts = ({type, products, closeModal }) => {
    return <div className='fixed top-0 bottom-0 z-10 flex right-0 h-full w-full  '>
        <div onClick={() => closeModal(false)} className=' bg-black bg-opacity-70 h-full  md:w-2/4 '></div>
        <div className='md:w-2/4 w-full bg-white h-full '>
            <div className=' p-2 flex justify-between  items-center '>
                <div className=' text-xs font'>{type}</div>
                <div className=' text-xs font mx-6 p-3' onClick={() => closeModal(false)} ><FaTimes className=' text-red-600'/></div>
            </div>
            <div className=' p-1 px-3 h-10 border m-2 flex justify-start items-center '>
                <BsSearch /> <input type="search" className='text-xs h-full w-full mx-2 outline-none' />
            </div>
            <div className=' list-products m-2 h-full overflow-y-auto pb-96'>
                { products?
                    products.map((product) =>{
                        return <div className=' md:h-40 flex md:flex-row flex-col my-1 w-full bg-slate-100 hover:border-gray-400 border-white border duration-700'>
                        <div className=' bg-white h-40 m-1 w-36 ' style={{ backgroundImage: `url(${product.image})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} ></div>
                        <div className='mx-3 w-96'>
                            <div className=' font-semibold text-xs my-1'>{product.model}</div>
                            <div className=' font-normal  text-xs my-1'>{product.type}</div>
                            <div className=' font-thin  text-xs my-1'>{product.serie}</div>
                            <div className=' font-thin text-xs my-1'>{product.caracteristic}</div>
                            <div className={`flex items-center gap-1 my-1 text-xs cursor-pointer py-1 ${product.entreprise === `HIKVISION` ? `text-red-700` : `text-blue-700`}  hover:${product.entreprise === 'HIKVISION' ? 'text-red-800' : 'text-blue-800'} hover:font-semibold`} onClick={() => window.open(product.link, '_blank')}>
                                <BsBookmarkFill />
                                {`Lire plus chez ${product.entreprise}`}
                                </div>
                            <div className='flex'>
                                    {/* <div className=' text-xs bg-gray-200 p-1 '> $320</div> */}
                            </div>
                        </div>
                    </div>
                    }): <div></div>
                }
                
                
            </div>
        </div>
    </div>
}

export default Products;
